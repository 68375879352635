import BarLoader from "react-spinners/BarLoader";

const LoadingSpinner = ({ color, loading = false, noRadius = false }) => {
  if (!loading) {
    return <></>;
  }

  return (
    <div
      className="sweet-loading"
      style={{
        position: "absolute",
        overflow: "hidden",
        borderRadius: noRadius ? 0 : 8,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        textAlign: "center",
        backgroundColor: "rgb(0 0 0 / 0%)",
      }}
    >
      <BarLoader
        color={color}
        loading={loading}
        size={10}
        cssOverride={{ width: "100%" }}
      />
    </div>
  );
};

export default LoadingSpinner;
