import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Container, Row, Col } from "react-grid-system";

// import { Row, Column } from "@input-output-hk/react-grid";
import { Button } from "evergreen-ui";
import { FaUser } from "react-icons/fa";

import "./AvailabilityPicker.scss";

const MAX_PAGINATION_SIZE = 12;

const defaultPagination = {
  page: 1,
  size: MAX_PAGINATION_SIZE,
  total: MAX_PAGINATION_SIZE,
  nextPageText: undefined,
  previousPageText: undefined,
};

function AvailabilityPicker({
  hideCapacity,
  tickets,
  availabilities,
  onChange,
  onClear,
  selectedAvailability,
  brandColor,
}) {
  const intl = useIntl();
  const [pagination, setPagination] = useState(defaultPagination);

  const findNextPrevTexts = (availabilities, pagination) => {
    let nextPageText;
    let previousPageText;

    if (availabilities.length <= MAX_PAGINATION_SIZE) {
      return { nextPageText, previousPageText };
    }

    const currentPageAvailabilities = availabilities.slice(
      (pagination.page - 1) * pagination.size,
      pagination.page * pagination.size
    );

    const [firstAvailability] = currentPageAvailabilities;

    const lastAvailability =
      currentPageAvailabilities[currentPageAvailabilities.length - 1];

    const hasPreviousPage =
      pagination.page === 1
        ? false
        : (pagination.page - 1) * pagination.size < pagination.total;

    const hasNextPage = pagination.page * pagination.size < pagination.total;

    if (hasPreviousPage && firstAvailability) {
      previousPageText = `${intl.formatMessage({
        id: "forms.availability.beforeTime.label",
      })} ${firstAvailability.startTime}`;
    }

    if (hasNextPage && lastAvailability) {
      nextPageText = `${intl.formatMessage({
        id: "forms.availability.afterTime.label",
      })} ${lastAvailability.startTime}`;
    }

    return { nextPageText, previousPageText };
  };

  useEffect(() => {
    const updatedPagination = {
      ...pagination,
      page: 1,
      total: availabilities.length,
    };

    const { nextPageText, previousPageText } = findNextPrevTexts(
      availabilities,
      updatedPagination
    );

    setPagination({
      ...updatedPagination,
      nextPageText,
      previousPageText,
    });
  }, [availabilities]);

  const handleClickNextPage = () => {
    const updatedPagination = {
      ...pagination,
      page: pagination.page + 1,
    };

    const { nextPageText, previousPageText } = findNextPrevTexts(
      availabilities,
      updatedPagination
    );

    setPagination({
      ...updatedPagination,
      nextPageText,
      previousPageText,
    });

    onClear();
  };

  const handleClickPrevPage = () => {
    const updatedPagination = {
      ...pagination,
      page: pagination.page - 1,
    };

    const { nextPageText, previousPageText } = findNextPrevTexts(
      availabilities,
      updatedPagination
    );

    setPagination({
      ...updatedPagination,
      nextPageText,
      previousPageText,
    });

    onClear();
  };

  const currentPageAvailabilities = availabilities.slice(
    (pagination.page - 1) * pagination.size,
    pagination.page * pagination.size
  );

  return (
    <Container className="availability-picker">
      <Row className="availability-picker-container">
        {currentPageAvailabilities.map((availability) => {
          const isSelected =
            selectedAvailability && selectedAvailability.id === availability.id;

          const isDisabled = tickets > availability.capacity;

          return (
            <Col key={availability.id} xs={4} sm={3}>
              <Button
                disabled={isDisabled}
                appearance="minimal"
                className={`availability-button ${
                  isSelected ? "selected" : isDisabled ? "disabled" : ""
                }`}
                style={isSelected ? { backgroundColor: brandColor } : {}}
                onClick={() => onChange(availability)}
              >
                <div style={{ margin: "auto" }}>
                  <span>{availability.startTime} </span>
                  {!hideCapacity && (
                    <span style={{ fontSize: "0.7em", margin: "0 4px 10px 0" }}>
                      {availability.capacity}
                      <FaUser size={"0.7em"} />
                    </span>
                  )}
                </div>
              </Button>
            </Col>
          );
        })}
      </Row>

      {(pagination.previousPageText || pagination.nextPageText) && (
        <div className="row flex-middle" style={{ marginTop: 30 }}>
          {pagination.previousPageText && (
            <div className="col col-start">
              <div
                className="icon"
                style={{ color: brandColor, cursor: "pointer" }}
                onClick={handleClickPrevPage}
              >
                chevron_left
              </div>
              <span
                style={{ cursor: "pointer", fontWeight: 500 }}
                onClick={handleClickPrevPage}
              >
                {pagination.previousPageText}
              </span>
            </div>
          )}

          {pagination.nextPageText && (
            <div className="col col-end">
              <span
                style={{ cursor: "pointer", fontWeight: 500 }}
                onClick={handleClickNextPage}
              >
                {pagination.nextPageText}
              </span>
              <div
                className="icon"
                style={{ color: brandColor, cursor: "pointer" }}
                onClick={handleClickNextPage}
              >
                chevron_right
              </div>
            </div>
          )}
        </div>
      )}
    </Container>
  );
}

export default AvailabilityPicker;
