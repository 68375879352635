import React, { useEffect } from "react";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { FaCalendarAlt } from "react-icons/fa";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styled from "styled-components";

const dateFormat = "YYYY-MM-DD";

const moment = extendMoment(Moment);

const DatePicker = ({
  dateNavigationRange,
  date: selectedDate,
  allowedDates,
  disabled = false,
  onChange,
  onChangeDateNavigation,
  brandColor,
  placeholder,
}) => {
  const { from, until } = dateNavigationRange;

  const momentFrom = moment(from, dateFormat);
  const momentUntil = moment(until, dateFormat);
  const momentRange = moment.range(momentFrom, momentUntil);
  const datesInRange = Array.from(momentRange.by("days")).map((day) =>
    day.format(dateFormat)
  );

  const momentSelectedDate = selectedDate && moment(selectedDate, dateFormat);

  const setDate = (date) => {
    onChange(moment(date).format(dateFormat));
  };

  const momentDisabledDates = (
    !allowedDates
      ? datesInRange
      : datesInRange.filter((date) => !allowedDates.includes(date))
  ).map((date) => moment(date, dateFormat));

  const handleDecreaseMonth = () => {
    const newFrom = moment(from, dateFormat)
      .subtract(1, "month")
      .startOf("month")
      .format(dateFormat);

    const newUntil = moment(until, dateFormat)
      .subtract(1, "month")
      .endOf("month")
      .format(dateFormat);

    onChangeDateNavigation({
      from: newFrom,
      until: newUntil,
    });
  };

  const handleIncreaseMonth = () => {
    const newFrom = moment(from, dateFormat)
      .add(1, "month")
      .startOf("month")
      .format(dateFormat);

    const newUntil = moment(until, dateFormat)
      .add(1, "month")
      .endOf("month")
      .format(dateFormat);

    onChangeDateNavigation({
      from: newFrom,
      until: newUntil,
    });
  };

  return (
    <WrappedDiv
      className="date-picker-container"
      brandColor={brandColor}
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: 4,
        border: "1px solid #dbdfe3",
        paddingLeft: 10,
        background: disabled ? "#f6f7f8" : "white",
      }}
    >
      <FaCalendarAlt size={"0.8rem"} color={brandColor} />

      <ReactDatePicker
        formatWeekDay={(dayName) => {
          return moment()
            .weekday(
              [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].indexOf(dayName)
            )
            .format("ddd")
            .toUpperCase();
        }}
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
        disabledKeyboardNavigation
        onFocus={(e) => e.target.blur()}
        placeholderText={placeholder}
        selected={momentSelectedDate && momentSelectedDate.toDate()}
        onChange={(date) => setDate(date)}
        className="date-picker"
        minDate={momentFrom.toDate()}
        maxDate={momentUntil.toDate()}
        excludeDates={momentDisabledDates.map((date) => date.toDate())}
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
        }) => (
          <div>
            {moment(from, dateFormat).isAfter(moment(), "month") && (
              <button
                disabled={disabled}
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                onClick={() => {
                  decreaseMonth();
                  handleDecreaseMonth();
                }}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
            )}

            <span className="react-datepicker__current-month">
              {moment(monthDate).format("MMM YYYY").toUpperCase()}
            </span>
            <button
              disabled={disabled}
              aria-label="Next Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--next"
              }
              onClick={() => {
                increaseMonth();
                handleIncreaseMonth();
              }}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                }
              >
                {">"}
              </span>
            </button>
          </div>
        )}
      />
    </WrappedDiv>
  );
};

const WrappedDiv = styled.div`
  .react-datepicker__input-container {
    border: none !important;
  }

  .date-picker {
    color: black;
    width: 100%;
    border: none;
    border-radius: 4px;
    height: 32px;
    padding-left: 10px;
    cursor: pointer;
  }

  input::placeholder {
    color: gray !important;
    font-weight: normal !important;
  }

  .date-picker:focus-visible {
    outline: none;
  }

  .react-datepicker__header {
    background-color: white !important;
  }

  .react-datepicker {
    border: 1px solid #dbdfe3 !important;
    border-radius: 4px !important;
  }

  .react-datepicker__current-month {
    color: black !important;
    font-weight: 500 !important;
  }

  .react-datepicker__day {
    line-height: 1.2rem;
  }

  .react-datepicker__day:hover {
    background-color: ${(props) => props.brandColor} !important;
    opacity: 0.7 !important;
    color: white !important;
  }

  .react-datepicker__day--selected {
    background-color: ${(props) => props.brandColor} !important;
    color: white !important;
    font-weight: bold !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: unset !important;
  }
`;

export default DatePicker;
