import React, { useEffect } from "react";
import moment from "moment";
import { IntlProvider } from "react-intl";

import BookingWidget from "../components/BookingWidget";

import AppLocale from "../lang";

import "moment/locale/el";
import "moment/locale/en-gb";
import "moment/locale/it";
import "moment/locale/de";
import "moment/locale/fr";

const DEFAULT_LANG = "en";

export const App = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsLang = params.get("lang");

  const lang = paramsLang && AppLocale[paramsLang] ? paramsLang : DEFAULT_LANG;

  const appLocale = AppLocale[lang];

  useEffect(() => {
    // Define global moment locale and week start by sunday
    moment.locale(lang, { week: { dow: 0 } });
  }, [lang]);

  return (
    <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
      <BookingWidget />
    </IntlProvider>
  );
};

export default App;
