import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Row, Col } from "react-grid-system";
import { toaster } from "evergreen-ui";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import IntlMessage from "../shared/IntlMessage";

import Footer from "../shared/Footer";

import clientUrls from "../../urls";

import TimePicker from "./TimePicker";
import DatePicker from "../transfer/DatePicker";

import MinMaxPicker from "../transfer/MinMaxPicker";

const dateFormat = "YYYY-MM-DD";

let lastFetchStartDatesId = 0;
let lastFetchStartTimesId = 0;
let lastFetchEndDatesId = 0;
let lastFetchEndTimesId = 0;

const RentalBookingParent = ({
  product,
  channel,
  pricingOptionId,
  date: defaultStartDate,
  viewMode,
  APPCONFIG,
  originURL,
  onLoading,
}) => {
  const intl = useIntl();

  let intervalFetchStartDates = useRef();

  const { rentalCategoryId: rentalCategory } = product;

  const [startNavigationRange, setStartNavigationRange] = useState({
    from: defaultStartDate
      ? moment(defaultStartDate, dateFormat).startOf("month").format(dateFormat)
      : moment().startOf("month").format(dateFormat),
    until: defaultStartDate
      ? moment(defaultStartDate, dateFormat).endOf("month").format(dateFormat)
      : moment().endOf("month").format(dateFormat),
  });

  const [endNavigationRange, setEndNavigationRange] = useState({
    from: defaultStartDate
      ? moment(defaultStartDate, dateFormat).startOf("month").format(dateFormat)
      : moment().startOf("month").format(dateFormat),
    until: defaultStartDate
      ? moment(defaultStartDate, dateFormat).endOf("month").format(dateFormat)
      : moment().endOf("month").format(dateFormat),
  });

  const [fetchStartDatesKey, setFetchStartDatesKey] = useState(undefined);
  const [fetchStartTimesKey, setFetchStartTimesKey] = useState(undefined);
  const [fetchEndDatesKey, setFetchEndDatesKey] = useState(undefined);
  const [fetchEndTimesKey, setFetchEndTimesKey] = useState(undefined);

  const [allowedStartDates, setAllowedStartDates] = useState([]);
  const [allowedStartTimes, setAllowedStartTimes] = useState([]);
  const [allowedEndDates, setAllowedEndDates] = useState([]);
  const [allowedEndTimes, setAllowedEndTimes] = useState([]);

  const [startDate, setStartDate] = useState(undefined);
  const [startTime, setStartTime] = useState(undefined);

  const [endDate, setEndDate] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);

  const [tickets, setTickets] = useState(Number(product.minTickets));

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const fetchAllowedStartDates = () => {
    lastFetchStartDatesId += 1;
    const fetchId = lastFetchStartDatesId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/rental/startdates?channelId=${encodeURIComponent(
        channel._id
      )}&from=${encodeURIComponent(
        startNavigationRange.from
      )}&until=${encodeURIComponent(
        startNavigationRange.until
      )}&tickets=${tickets}${
        pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""
      }`
    )
      .then((response) => response.json())
      .then(({ startDates }) => {
        if (fetchId > lastFetchStartDatesId) {
          // for fetch callback order
          return;
        }

        if (fetchId > lastFetchStartDatesId) {
          // for fetch callback order
          return;
        }

        setAllowedStartDates(startDates);
        setFetchStartDatesKey(uuidv4());
        onLoading(false);
      });
  };

  const fetchAllowedStartTimes = () => {
    lastFetchStartTimesId += 1;
    const fetchId = lastFetchStartTimesId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/rental/starttimes?channelId=${encodeURIComponent(
        channel._id
      )}&startDate=${encodeURIComponent(startDate)}&tickets=${tickets}${
        pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""
      }`
    )
      .then((response) => response.json())
      .then(({ startTimes }) => {
        if (fetchId > lastFetchStartTimesId) {
          // for fetch callback order
          return;
        }

        setAllowedStartTimes(startTimes);
        setFetchStartTimesKey(uuidv4());
        onLoading(false);
      });
  };

  const fetchAllowedEndDates = () => {
    lastFetchEndDatesId += 1;
    const fetchId = lastFetchEndDatesId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/rental/enddates?channelId=${encodeURIComponent(
        channel._id
      )}&startDate=${encodeURIComponent(
        startDate
      )}&startTime=${encodeURIComponent(startTime)}&tickets=${tickets}${
        pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""
      }`
    )
      .then((response) => response.json())
      .then(({ endDates }) => {
        if (fetchId > lastFetchEndDatesId) {
          // for fetch callback order
          return;
        }

        setAllowedEndDates(endDates);
        setFetchEndDatesKey(uuidv4());
        onLoading(false);
      });
  };

  const fetchAllowedEndTimes = () => {
    lastFetchEndTimesId += 1;
    const fetchId = lastFetchEndTimesId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/rental/endtimes?channelId=${encodeURIComponent(
        channel._id
      )}&startDate=${encodeURIComponent(
        startDate
      )}&startTime=${encodeURIComponent(
        startTime
      )}&endDate=${encodeURIComponent(endDate)}&tickets=${tickets}${
        pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""
      }`
    )
      .then((response) => response.json())
      .then(({ endTimes }) => {
        if (fetchId > lastFetchEndTimesId) {
          // for fetch callback order
          return;
        }

        setAllowedEndTimes(endTimes);
        setFetchEndTimesKey(uuidv4());
        onLoading(false);
      });
  };

  const handleSubmit = () => {
    if (!startDate) {
      toaster.warning(
        intl.formatMessage({
          id: `forms.rental.rentalCategory.${rentalCategory}.startDate.requiredMessage`,
        }),
        { duration: 2 }
      );

      return;
    }

    if (!startTime) {
      toaster.warning(
        intl.formatMessage({
          id: `forms.rental.rentalCategory.${rentalCategory}.startTime.requiredMessage`,
        }),
        { duration: 2 }
      );

      return;
    }

    if (!endDate) {
      toaster.warning(
        intl.formatMessage({
          id: `forms.rental.rentalCategory.${rentalCategory}.endDate.requiredMessage`,
        }),
        { duration: 2 }
      );

      return;
    }

    if (!endTime) {
      toaster.warning(
        intl.formatMessage({
          id: `forms.rental.rentalCategory.${rentalCategory}.endTime.requiredMessage`,
        }),
        { duration: 2 }
      );

      return;
    }

    const dataToPost = {
      activityId: product._id,
      channelId: channel._id,
      pricingOptionId,
      dateToTravel: startDate,
      timeToTravel: startTime,
      endDate: endDate,
      endTime: endTime,
      tickets,
      originURL,
    };

    setLoadingSubmit(true);

    fetch(`${clientUrls.api}/public/tempsearch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(dataToPost),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setLoadingSubmit(false);

          toaster.warning(intl.formatMessage({ id: "common.genericError" }), {
            duration: 2,
          });

          return;
        }

        setLoadingSubmit(false);

        window.open(
          `https://${APPCONFIG.subdomain}/book/new/product/${data.resp._id}?lang=${intl.locale}`,
          "_top"
        );
      });
  };

  const handleChangeEndNavigationRange = ({ from, until }) => {
    setEndNavigationRange({ from, until });
  };

  const handleChangeStartNavigationRange = ({ from, until }) => {
    setStartNavigationRange({ from, until });
    setEndNavigationRange({ from, until });
  };

  useEffect(() => {
    if (!startDate) {
      return;
    }

    setStartTime(undefined);
    setEndDate(undefined);
    setEndTime(undefined);

    setAllowedStartTimes([]);
    setAllowedEndDates([]);
    setAllowedEndTimes([]);

    fetchAllowedStartTimes();
  }, [startDate]);

  useEffect(() => {
    if (!startTime) {
      return;
    }

    setEndDate(undefined);
    setEndTime(undefined);

    setAllowedEndDates([]);
    setAllowedEndTimes([]);

    fetchAllowedEndDates();
  }, [startTime]);

  useEffect(() => {
    if (!endDate) {
      return;
    }

    setEndTime(undefined);

    setAllowedEndTimes([]);
    fetchAllowedEndTimes();
  }, [endDate]);

  // Fetch start dates initially or whenever date navigation changes
  useEffect(() => {
    clearInterval(intervalFetchStartDates.current);

    setStartDate(undefined);
    setStartTime(undefined);
    setEndDate(undefined);
    setEndTime(undefined);

    setAllowedStartDates([]);
    setAllowedStartTimes([]);
    setAllowedEndDates([]);
    setAllowedEndTimes([]);

    const delayMs = !fetchStartDatesKey ? 0 : 500;

    intervalFetchStartDates.current = setInterval(() => {
      fetchAllowedStartDates();
      clearInterval(intervalFetchStartDates.current);
    }, delayMs);
  }, [tickets, startNavigationRange.from, startNavigationRange.until]);

  // If there is only one available start date, preselect it
  useEffect(() => {
    if (!fetchStartDatesKey || startDate) {
      return;
    }

    if (allowedStartDates.length === 1) {
      setStartDate(allowedStartDates[0]);
    } else if (
      defaultStartDate &&
      allowedStartDates.includes(defaultStartDate)
    ) {
      setStartDate(defaultStartDate);
    }
  }, [fetchStartDatesKey]);

  // If there is only one available start time, preselect it
  useEffect(() => {
    if (!fetchStartTimesKey || startTime || !startDate) {
      return;
    }

    if (allowedStartTimes.length === 1) {
      setStartTime(allowedStartTimes[0]);
    }
  }, [fetchStartTimesKey]);

  // If there is only one available end date, preselect it
  useEffect(() => {
    if (!fetchEndDatesKey || endDate) {
      return;
    }

    if (allowedEndDates.length === 1) {
      setEndDate(allowedEndDates[0]);
    }
  }, [fetchEndDatesKey]);

  // If there is only one available end time, preselect it
  useEffect(() => {
    if (!fetchEndTimesKey || endTime || !endDate) {
      return;
    }

    if (allowedEndTimes.length === 1) {
      setEndTime(allowedEndTimes[0]);
    }
  }, [fetchEndTimesKey]);

  return (
    <div>
      {(product.minTickets !== "1" || product.maxTickets !== "1") && (
        <>
          <p>
            <IntlMessage id={`forms.rental.tickets.label`} />
          </p>
          <Row style={{ marginBottom: 25 }}>
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <MinMaxPicker
                value={tickets}
                min={Number(product.minTickets)}
                max={Number(product.maxTickets)}
                onChange={setTickets}
                brandColor={channel.normalColor}
                icon="person"
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <p>
            <IntlMessage
              id={`forms.rental.rentalCategory.${rentalCategory}.startDate.label`}
            />
          </p>

          <DatePicker
            dateNavigationRange={startNavigationRange}
            date={startDate}
            allowedDates={allowedStartDates}
            placeholder={intl.formatMessage({
              id: `forms.rental.rentalCategory.${rentalCategory}.startDate.placeholder`,
            })}
            onChange={setStartDate}
            onChangeDateNavigation={handleChangeStartNavigationRange}
            brandColor={channel.normalColor}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <p>
            <IntlMessage
              id={`forms.rental.rentalCategory.${rentalCategory}.startTime.label`}
            />
          </p>

          <TimePicker
            time={startTime}
            allowedTimes={allowedStartTimes}
            placeholder={intl.formatMessage({
              id: `forms.rental.rentalCategory.${rentalCategory}.startTime.placeholder`,
            })}
            disabled={!startDate}
            onChange={setStartTime}
            brandColor={channel.normalColor}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 25 }}>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <p>
            <IntlMessage
              id={`forms.rental.rentalCategory.${rentalCategory}.endDate.label`}
            />
          </p>

          <DatePicker
            dateNavigationRange={endNavigationRange}
            date={endDate}
            allowedDates={allowedEndDates}
            placeholder={intl.formatMessage({
              id: `forms.rental.rentalCategory.${rentalCategory}.endDate.placeholder`,
            })}
            disabled={!startDate || !startTime}
            onChange={setEndDate}
            onChangeDateNavigation={handleChangeEndNavigationRange}
            brandColor={channel.normalColor}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <p>
            <IntlMessage
              id={`forms.rental.rentalCategory.${rentalCategory}.endTime.label`}
            />
          </p>

          <TimePicker
            time={endTime}
            allowedTimes={allowedEndTimes}
            placeholder={intl.formatMessage({
              id: `forms.rental.rentalCategory.${rentalCategory}.endTime.placeholder`,
            })}
            disabled={!startDate || !startTime || !endDate}
            addExtraMinute
            onChange={setEndTime}
            brandColor={channel.normalColor}
          />
        </Col>
      </Row>

      <Footer
        viewMode={viewMode}
        onClick={handleSubmit}
        APPCONFIG={APPCONFIG}
        brandColor={channel.normalColor}
        loadingButton={loadingSubmit}
      />
    </div>
  );
};

export default RentalBookingParent;
