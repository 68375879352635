import React from "react";
import { injectIntl } from "react-intl";
import { Grid, Col } from "react-grid-easy";

import moment from "moment";

import { toaster } from "evergreen-ui";

import AvailabilitiesCalendar from "./AvailabilitiesCalendar";
import TicketsPicker from "./TicketsPicker";
import AvailabilityPicker from "./AvailabilityPicker";
import Footer from "../shared/Footer";

import IntlMessage from "../shared/IntlMessage";

import clientUrls from "../../urls";

const dateFormat = "YYYY-MM-DD";

class ActivityBookingParent extends React.Component {
  constructor(props) {
    super(props);

    this.lastFetchId = 0;

    const { product, date, persons } = props;

    this.state = {
      from: date
        ? moment(date, dateFormat).startOf("month").format(dateFormat)
        : moment().startOf("month").format(dateFormat),

      until: date
        ? moment(date, dateFormat).endOf("month").format(dateFormat)
        : moment().endOf("month").format(dateFormat),

      availabilitiesPerDay: [],
      loadingAvailabilitiesPerDay: false,

      availabilitiesOnSelectedDay: [],

      selectedDate: undefined,
      selectedAvailability: undefined,
      tickets: persons ? Number(persons) : Number(product.minTickets),

      loadingSubmit: false,
    };
  }

  componentDidMount() {
    const { date } = this.props;
    this.fetchAvailabilities(undefined, date);
  }

  fetchAvailabilities = (lastSelectedAvailability, lastSelectedDate) => {
    const { product, channel, pricingOptionId, onLoading } = this.props;

    const { from, until, tickets } = this.state;

    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${product._id}/channels/${
        channel._id
      }/availabilities/bookable?from=${encodeURIComponent(
        from
      )}&until=${encodeURIComponent(until)}&tickets=${encodeURIComponent(
        tickets
      )}${pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""}`
    )
      .then((response) => response.json())
      .then(({ availabilitiesPerDay }) => {
        if (fetchId > this.lastFetchId) {
          // for fetch callback order
          return;
        }

        onLoading(false);

        this.setState(
          {
            availabilitiesPerDay,
            loadingAvailabilitiesPerDay: false,
          },
          () => {
            if (lastSelectedAvailability) {
              this.handleChangeSelectedDate(
                lastSelectedAvailability.startDate,
                lastSelectedAvailability
              );
            } else if (lastSelectedDate) {
              this.handleChangeSelectedDate(lastSelectedDate);
            }
          }
        );
      });
  };

  handleChangeSelectedDate = (selectedDate, lastSelectedAvailability) => {
    const { availabilitiesPerDay } = this.state;

    const dateAvailabilities = availabilitiesPerDay.find(
      ({ date }) => date === selectedDate
    );

    if (!dateAvailabilities) {
      return;
    }

    const { availability: availabilitiesOnSelectedDay } = dateAvailabilities;

    this.setState(
      {
        availabilitiesOnSelectedDay,
        selectedDate,
        selectedAvailability: undefined,
      },
      () => {
        if (availabilitiesOnSelectedDay.length === 1) {
          this.handleChangeAvailability(availabilitiesOnSelectedDay[0]);
        } else if (lastSelectedAvailability) {
          this.handleChangeAvailability(lastSelectedAvailability);
        }
      }
    );
  };

  handleChangeTickets = (tickets) => {
    const {
      selectedAvailability: lastSelectedAvailability,
      selectedDate: lastSelectedDate,
    } = this.state;

    this.setState(
      {
        tickets,
        selectedDate: undefined,
        selectedAvailability: undefined,
        availabilitiesOnSelectedDay: [],
      },
      () => {
        clearInterval(this.interval);

        this.interval = setInterval(() => {
          this.fetchAvailabilities(lastSelectedAvailability, lastSelectedDate);

          clearInterval(this.interval);
        }, 600);
      }
    );
  };

  handleClearAvailability = () => {
    this.setState({ selectedAvailability: undefined });
  };

  handleChangeAvailability = (selected) => {
    const { availabilitiesOnSelectedDay, tickets } = this.state;

    const selectedAvailability = availabilitiesOnSelectedDay.find(
      ({ id, startDate, startTime, endDate, endTime, capacity }) =>
        capacity >= tickets &&
        (id === selected.id ||
          (startDate === selected.startDate &&
            startTime === selected.startTime &&
            endDate === selected.endDate &&
            endTime === selected.endTime))
    );

    if (!selectedAvailability) {
      return;
    }

    this.setState({ selectedAvailability });
  };

  handleChangeDateNavigation = ({ from, until }) => {
    this.setState(
      {
        from,
        until,
        selectedDate: undefined,
        availabilitiesOnSelectedDay: [],
        selectedAvailability: undefined,
      },
      () => {
        this.fetchAvailabilities();
      }
    );
  };

  handleSubmit = () => {
    const { intl } = this.props;

    const { product, channel, pricingOptionId, originURL, APPCONFIG } =
      this.props;
    const { selectedDate, selectedAvailability, tickets } = this.state;

    if (!selectedDate) {
      toaster.warning(
        intl.formatMessage({
          id: "forms.availability.date.requiredMessage",
        }),
        { duration: 2 }
      );

      return;
    }

    if (!selectedAvailability) {
      toaster.warning(
        intl.formatMessage({
          id: "forms.availability.time.requiredMessage",
        }),
        { duration: 2 }
      );

      return;
    }

    const dataToPost = {
      activityId: product._id,
      channelId: channel._id,
      pricingOptionId,
      dateToTravel: selectedAvailability.startDate,
      timeToTravel: selectedAvailability.startTime,
      tickets,
      originURL,
    };

    this.setState({ loadingSubmit: true });

    fetch(`${clientUrls.api}/public/tempsearch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(dataToPost),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          this.setState({ loadingSubmit: false });

          toaster.warning(intl.formatMessage({ id: "common.genericError" }), {
            duration: 2,
          });

          return;
        }

        this.setState({ loadingSubmit: false });

        window.open(
          `https://${APPCONFIG.subdomain}/book/new/product/${data.resp._id}?lang=${intl.locale}`,
          "_top"
        );
      });
  };

  render() {
    const { viewMode, hideCapacity } = this.props;
    const { product, channel, APPCONFIG } = this.props;

    const {
      from,
      until,
      availabilitiesPerDay,
      availabilitiesOnSelectedDay,
      selectedAvailability,
      selectedDate,
      tickets,
    } = this.state;

    const { loadingSubmit } = this.state;

    return (
      <Grid>
        <Col xs={24} style={{ marginTop: viewMode === "modal" ? -20 : 0 }}>
          {viewMode === "modal" && (
            <p>
              <IntlMessage id="forms.availability.date.label" />
            </p>
          )}

          <AvailabilitiesCalendar
            hideCapacity={hideCapacity}
            from={from}
            until={until}
            tickets={tickets}
            availabilitiesPerDay={availabilitiesPerDay}
            selectedAvailability={selectedAvailability}
            selectedDate={selectedDate}
            onChangeSelectedDate={this.handleChangeSelectedDate}
            onChangeDateNavigation={this.handleChangeDateNavigation}
            brandColor={channel.normalColor}
          />
        </Col>

        <Col style={{ marginTop: "1em" }} xs={24}>
          {viewMode === "modal" && (
            <p>
              <IntlMessage id="forms.availability.tickets.label" />
            </p>
          )}

          <TicketsPicker
            min={Number(product.minTickets)}
            max={Number(product.maxTickets)}
            current={tickets}
            onChange={this.handleChangeTickets}
            brandColor={channel.normalColor}
          />
        </Col>

        {selectedDate ? (
          product.productType === "multiday" &&
          availabilitiesOnSelectedDay.length === 1 ? (
            ""
          ) : (
            <Col style={{ marginTop: "1em" }} xs={24}>
              {viewMode === "modal" && (
                <p>
                  <IntlMessage id="forms.availability.time.label" />
                </p>
              )}

              <AvailabilityPicker
                hideCapacity={hideCapacity}
                tickets={tickets}
                availabilities={availabilitiesOnSelectedDay}
                selectedAvailability={selectedAvailability}
                onChange={this.handleChangeAvailability}
                onClear={this.handleClearAvailability}
                brandColor={channel.normalColor}
              />
            </Col>
          )
        ) : (
          ""
        )}

        <Footer
          viewMode={viewMode}
          onClick={this.handleSubmit}
          APPCONFIG={APPCONFIG}
          brandColor={channel.normalColor}
          loadingButton={loadingSubmit}
        />
      </Grid>
    );
  }
}

export default injectIntl(ActivityBookingParent);
