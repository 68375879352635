import enMsg from "../locales/en.json";

const EnLang = {
  locale: "en",
  messages: {
    ...enMsg,
  },
};

export default EnLang;
