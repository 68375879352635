import React from "react";
import { useIntl } from "react-intl";
import { Pane, Radio } from "evergreen-ui";

import styled from "styled-components";

import "./TransferBookRadio.scss";

const TransferBookRadio = ({ transferBookType, onChange, brandColor }) => {
  const intl = useIntl();

  return (
    <WrappedDiv
      brandColor={brandColor}
      className="transfer-booking-type-radiogroup"
    >
      <Radio
        appearance="minimal"
        onClick={(v) => onChange("single")}
        checked={transferBookType === "single"}
        name="group"
        label={intl.formatMessage({
          id: "forms.transfer.transferBookType.single.label",
        })}
        value={"single"}
      />
      <Radio
        appearance="minimal"
        style={{ marginLeft: 15 }}
        onClick={(v) => onChange("roundtrip")}
        checked={transferBookType === "roundtrip"}
        name="group"
        label={intl.formatMessage({
          id: "forms.transfer.transferBookType.roundtrip.label",
        })}
        value={"roundtrip"}
      />
    </WrappedDiv>
  );
};

const WrappedDiv = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 15px !important;
    color: #808080 !important;
  }
  
  input[type="radio"]:checked + div {
    background-image: none !important;
    box-shadow: none !important;
    background-color: ${(props) => props.brandColor} !important;
  }
`;

export default TransferBookRadio;
