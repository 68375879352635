import React from "react";
import moment from "moment";
import { FaUndoAlt } from "react-icons/fa";
import styled from "styled-components";

import "./AvailabilitiesCalendar.css";

import IntlMessage from "../shared/IntlMessage";

const dateFormat = "YYYY-MM-DD";

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

class AvailabilitiesCalendar extends React.Component {
  nextMonth = () => {
    const { from: currentFrom, onChangeDateNavigation } = this.props;

    const nextMonth = moment(currentFrom, dateFormat).add(1, "months");

    const from = nextMonth.startOf("month").format(dateFormat);
    const until = nextMonth.endOf("month").format(dateFormat);

    onChangeDateNavigation({ from, until });
  };

  prevMonth = () => {
    const { from: currentFrom, onChangeDateNavigation } = this.props;

    const prevMonth = moment(currentFrom, dateFormat).subtract(1, "months");

    const from = prevMonth.startOf("month").format(dateFormat);
    const until = prevMonth.endOf("month").format(dateFormat);

    onChangeDateNavigation({ from, until });
  };

  jumpToToday = () => {
    const { onChangeDateNavigation } = this.props;

    const monthToday = moment();

    const from = monthToday.startOf("month").format(dateFormat);
    const until = monthToday.endOf("month").format(dateFormat);

    onChangeDateNavigation({ from, until });
  };

  renderHeader() {
    const { from, brandColor } = this.props;

    const monthFormat = "MMM YYYY";

    const viewIsOnCurrentMonth = moment(from, dateFormat).isSame(
      moment(),
      "month"
    );

    return (
      <div>
        <div className="header row flex-middle">
          <div className="col col-start">
            {!viewIsOnCurrentMonth && (
              <div
                className="icon"
                onClick={this.prevMonth}
                style={{ color: brandColor }}
              >
                chevron_left
              </div>
            )}
          </div>
          <div className="col col-center">
            <span>{moment(from).format(monthFormat)}</span>
          </div>
          <div className="col col-end">
            <div
              className="icon"
              onClick={this.nextMonth}
              style={{ color: brandColor }}
            >
              chevron_right
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFooter() {
    var { brandColor, from } = this.props;

    const monthNow = moment().format("YYYY-MM");
    const currentMonthView = moment(from, "YYYY-MM-DD").format("YYYY-MM");

    return (
      <div>
        <Footer brandColor={brandColor} className="cal-footer row flex-middle">
          <div
            className="col col-start"
            onClick={() => {
              if (monthNow !== currentMonthView) {
                this.jumpToToday();
              }
            }}
          >
            <b
              id="jump-to-today"
              style={{ fontSize: "10px", marginLeft: 10, color: brandColor }}
            >
              <FaUndoAlt size={"1em"} />{" "}
              <IntlMessage id="forms.availability.jumpToToday.label" />
            </b>
          </div>
          {/* <div className="col col-end" onClick={this.jumpToNextAvailableMonth}>
            <span
              id="jump-to-next-available-month"
              style={{ fontSize: "9px", marginRight: 10 }}
            >
              {"Next Available Month"} <div className="icon">chevron_right</div>
            </span>
          </div> */}
        </Footer>
      </div>
    );
  }

  renderWeekdays() {
    const weekdayFormat = "ddd";

    const days = [];

    const startDate = moment().startOf("week").toDate();

    for (let i = 0; i < 7; i++) {
      const dateFormat = moment(startDate).add(i, "days");

      days.push(
        <div className="col col-center" key={i}>
          {dateFormat.format(weekdayFormat).toUpperCase()}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  renderDays() {
    const { hideCapacity } = this.props;
    const { availabilitiesPerDay, selectedAvailability, tickets } = this.props;
    const { from, until, selectedDate, onChangeSelectedDate } = this.props;
    const { brandColor } = this.props;

    const monthStart = moment(from, dateFormat).startOf("month").toDate();
    const monthEnd = moment(until, dateFormat).endOf("month").toDate();

    const startDate = moment(monthStart).startOf("week").toDate();
    const endDate = moment(monthEnd).endOf("week").toDate();

    const rows = [];

    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const momentCloneDay = moment(day);
        const dateFormatted = momentCloneDay.format(dateFormat);

        const dateAvailabilities = availabilitiesPerDay.find(
          ({ date }) => date === dateFormatted
        );

        let isBookable;
        let maxCapacityOnDay = 0;
        let discountApplied;

        if (dateAvailabilities) {
          const { availability: availabilitiesOnDay } = dateAvailabilities;

          const hasAvailabilitiesWithRequiredTickets =
            availabilitiesOnDay.filter(({ capacity }) => tickets <= capacity)
              .length > 0;

          discountApplied = availabilitiesOnDay.some(
            (availability) => availability.discountApplied
          );

          isBookable = hasAvailabilitiesWithRequiredTickets;

          if (isBookable) {
            maxCapacityOnDay = Math.max(
              ...availabilitiesOnDay.map(({ capacity }) => capacity)
            );
          }
        }

        // Check if day is selected
        const isSelected = selectedDate === dateFormatted;

        let isSelectedAsStart, isSelectedAsMid, isSelectedAsEnd;
        if (selectedAvailability) {
          const { startDate, endDate } = selectedAvailability;

          isSelectedAsStart = startDate === dateFormatted;
          isSelectedAsEnd = endDate === dateFormatted;

          isSelectedAsMid =
            startDate < dateFormatted && dateFormatted < endDate;
        }

        const elemDay = !isBookable ? (
          <DivDisabledDate
            className="col cell disabled"
            key={dateFormatted}
            isSelectedAsMid={isSelectedAsMid}
            isSelectedAsEnd={isSelectedAsEnd}
            brandColor={brandColor}
          >
            <span className="number" style={{ fontWeight: "unset" }}>
              {momentCloneDay.format("DD")}
            </span>
          </DivDisabledDate>
        ) : isSelected ||
          isSelectedAsStart ||
          isSelectedAsMid ||
          isSelectedAsEnd ? (
          <DivSelectedDay
            className="col cell selected"
            key={dateFormatted}
            onClick={() => onChangeSelectedDate(dateFormatted)}
            selectedAvailability={selectedAvailability}
            isSelectedAsStart={isSelectedAsStart}
            isSelectedAsMid={isSelectedAsMid}
            isSelectedAsEnd={isSelectedAsEnd}
            isMultiday={
              selectedAvailability &&
              selectedAvailability.startDate !== selectedAvailability.endDate
            }
            brandColor={brandColor}
          >
            <span className="number">{momentCloneDay.format("DD")}</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <span className="number">{momentCloneDay.format("DD")}</span>

              {discountApplied && (isSelected || isSelectedAsStart) && (
                <TextBG
                  brandColor={"#00a854"}
                  style={{ fontSize: "0.9rem", marginRight: 2 }}
                >
                  %
                </TextBG>
              )}
            </div>

            {!hideCapacity && maxCapacityOnDay > 0 && (
              <TextBG
                brandColor={brandColor}
                className="bg"
                style={{ fontSize: "0.9rem", marginRight: 2, fontWeight: 500 }}
              >
                {maxCapacityOnDay}
              </TextBG>
            )}
          </DivSelectedDay>
        ) : (
          <div
            className="col cell"
            key={dateFormatted}
            onClick={() => onChangeSelectedDate(dateFormatted)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <span className="number">{momentCloneDay.format("DD")}</span>

              {discountApplied && (
                <TextBG
                  brandColor={"#00a854"}
                  style={{
                    fontSize: "0.9rem",
                    marginRight: 2,
                    fontWeight: 500,
                  }}
                >
                  %
                </TextBG>
              )}
            </div>

            {!hideCapacity && maxCapacityOnDay > 0 && (
              <TextBG
                brandColor={brandColor}
                className="bg"
                style={{ marginRight: 3, marginBottom: 3 }}
              >
                {maxCapacityOnDay}
              </TextBG>
            )}
          </div>
        );

        days.push(elemDay);

        day = moment(day).add(1, "days").toDate();
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );

      days = [];
    }

    return <div className="body">{rows}</div>;
  }

  render() {
    return (
      <div className="calendar">
        {this.renderHeader()}
        {this.renderWeekdays()}
        {this.renderDays()}
        {this.renderFooter()}
      </div>
    );
  }
}

const TextBG = styled.span`
  color: ${(props) => props.brandColor || "lightblue"};
`;

const DivSelectedDay = styled.div`
  background: ${(props) =>
    hexToRGB(props.brandColor || "lightblue", 0.1)} !important;

  ${(props) =>
    props.isSelectedAsMid || (props.isMultiday && props.isSelectedAsEnd)
      ? "border-left: none !important;"
      : `border-left: 2px ${
          props.selectedAvailability ? "solid" : "dashed"
        } ${hexToRGB(props.brandColor || "lightblue", 0.6)} !important;`}

  border-top: 2px ${(props) =>
    props.selectedAvailability ? "solid" : "dashed"} ${(props) =>
    hexToRGB(props.brandColor || "lightblue", 0.6)} !important;

  ${(props) =>
    props.isSelectedAsMid || (props.isMultiday && props.isSelectedAsStart)
      ? "border-right: none !important;"
      : `border-right: 2px ${
          props.selectedAvailability ? "solid" : "dashed"
        } ${hexToRGB(props.brandColor || "lightblue", 0.6)} !important;`}

  border-bottom: 2px ${(props) =>
    props.selectedAvailability ? "solid" : "dashed"} ${(props) =>
    hexToRGB(props.brandColor || "lightblue", 0.6)} !important;
`;

const DivDisabledDate = styled.div`
  background: rgba(210, 210, 210, 0.2) !important;
  pointer-events: none !important;

  ${(props) =>
    props.isSelectedAsMid || props.isSelectedAsEnd
      ? `border-top: 2px solid ${hexToRGB(
          props.brandColor || "lightblue",
          0.6
        )} !important;`
      : ""}

  ${(props) =>
    props.isSelectedAsMid || props.isSelectedAsEnd
      ? `border-bottom: 2px solid ${hexToRGB(
          props.brandColor || "lightblue",
          0.6
        )} !important;`
      : ""}

      ${(props) =>
    props.isSelectedAsEnd
      ? `border-right: 2px solid ${hexToRGB(
          props.brandColor || "lightblue",
          0.6
        )} !important;`
      : ""}
`;

const Footer = styled.div`
  color: #0044a9;
`;

export default AvailabilitiesCalendar;
