import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Row, Col } from "react-grid-system";
import { toaster } from "evergreen-ui";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import Footer from "../shared/Footer";

import clientUrls from "../../urls";

import MinMaxPicker from "./MinMaxPicker";
import TimePicker from "./TimePicker";
import RoutePicker from "./RoutePicker";
import TransferServicePicker from "./TransferServicePicker";
import DatePicker from "./DatePicker";
import TransferBookRadio from "./TransferBookRadio";

const dateFormat = "YYYY-MM-DD";

let lastFetchStartDatesId = 0;
let lastFetchStartTimesId = 0;

let lastFetchStartDatesRoundId = 0;
let lastFetchStartTimesRoundId = 0;

const validateTickets = ({ tickets, product }) => {
  const { minTickets, maxTickets } = product;

  const isValid =
    tickets >= Number(minTickets) && tickets <= Number(maxTickets);

  return isValid;
};

const validateTransferService = ({
  transferService,
  smallLuggageSize,
  largeLuggageSize,
  tickets,
  product,
}) => {
  const { transferServices, minMaxTicketsPerTransferService } = product;

  let isValid = transferServices.includes(transferService);

  if (minMaxTicketsPerTransferService?.[transferService]) {
    const { minTickets, maxTickets, maxLuggage } =
      minMaxTicketsPerTransferService[transferService];

    if (minTickets) {
      isValid = isValid && Number(tickets) >= minTickets;
    }

    if (maxTickets) {
      isValid = isValid && Number(tickets) <= maxTickets;
    }

    if (maxLuggage) {
      const totalLuggage = smallLuggageSize + largeLuggageSize * 2;
      isValid = isValid && Number(totalLuggage) <= maxLuggage;
    }
  }

  return isValid;
};

const TransferBookingParent = ({
  product,
  channel,
  pricingOptionId,
  pickupPointId: defaultPickupPointId,
  dropOffPointId: defaultDropOffPointId,
  date: defaultStartDate,
  persons: defaultTickets,
  transferService: defaultTransferService,
  viewMode,
  APPCONFIG,
  originURL,
  onLoading,
}) => {
  const intl = useIntl();

  const [dateNavigationRange, setDateNavigationRange] = useState({
    from: defaultStartDate
      ? moment(defaultStartDate, dateFormat).startOf("month").format(dateFormat)
      : moment().startOf("month").format(dateFormat),
    until: defaultStartDate
      ? moment(defaultStartDate, dateFormat).endOf("month").format(dateFormat)
      : moment().endOf("month").format(dateFormat),
  });

  const [dateRoundNavigationRange, setDateRoundNavigationRange] = useState({
    from: defaultStartDate
      ? moment(defaultStartDate, dateFormat).startOf("month").format(dateFormat)
      : moment().startOf("month").format(dateFormat),
    until: defaultStartDate
      ? moment(defaultStartDate, dateFormat).endOf("month").format(dateFormat)
      : moment().endOf("month").format(dateFormat),
  });

  let defaultTransferServiceToCheck;

  if (defaultTransferService) {
    defaultTransferServiceToCheck = defaultTransferService;
  } else if (product.transferServices.length === 1) {
    [defaultTransferServiceToCheck] = product.transferServices;
  }

  const [allowedStartDates, setAllowedStartDates] = useState([]);
  const [fetchStartDatesKey, setFetchStartDatesKey] = useState(undefined);
  const [allowedStartTimes, setAllowedStartTimes] = useState([]);

  const [allowedStartDatesRound, setAllowedStartDatesRound] = useState([]);
  const [allowedStartTimesRound, setAllowedStartTimesRound] = useState([]);

  const [pickupLocation, setPickupLocation] = useState(undefined);
  const [dropOffLocation, setDropOffLocation] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [startTime, setStartTime] = useState(undefined);
  const [startDateRound, setStartDateRound] = useState(undefined);
  const [startTimeRound, setStartTimeRound] = useState(undefined);
  const [transferBookType, setTransferBookType] = useState("single");
  const [smallLuggageSize, setSmallLuggageSize] = useState(0);
  const [largeLuggageSize, setLargeLuggageSize] = useState(0);

  const hideRoundTrip = product.productCategory === "tour";

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [tickets, setTickets] = useState(
    defaultTickets && validateTickets(defaultTickets)
      ? defaultTickets
      : Number(product.minTickets)
  );

  const [transferService, setTransferService] = useState(
    defaultTransferServiceToCheck &&
      validateTransferService({
        transferService: defaultTransferServiceToCheck,
        smallLuggageSize,
        largeLuggageSize,
        tickets,
        product,
      })
      ? defaultTransferServiceToCheck
      : undefined
  );

  const fetchStartDates = () => {
    lastFetchStartDatesId += 1;
    const fetchId = lastFetchStartDatesId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/transfer/startdates?channelId=${channel._id}&from=${encodeURIComponent(
        dateNavigationRange.from
      )}&until=${encodeURIComponent(
        dateNavigationRange.until
      )}&tickets=${encodeURIComponent(tickets)}${
        pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""
      }`
    )
      .then((response) => response.json())
      .then(({ startDates }) => {
        if (fetchId > lastFetchStartDatesId) {
          // for fetch callback order
          return;
        }

        onLoading(false);

        setAllowedStartDates(startDates);
        setFetchStartDatesKey(uuidv4());
      });
  };

  const fetchStartTimes = () => {
    lastFetchStartTimesId += 1;
    const fetchId = lastFetchStartTimesId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/transfer/starttimes?channelId=${
        channel._id
      }&startDate=${encodeURIComponent(startDate)}&tickets=${encodeURIComponent(
        tickets
      )}${pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""}`
    )
      .then((response) => response.json())
      .then(({ startTimes }) => {
        if (fetchId > lastFetchStartTimesId) {
          // for fetch callback order
          return;
        }

        onLoading(false);
        setAllowedStartTimes(startTimes);
      });
  };

  const fetchStartDatesRound = () => {
    lastFetchStartDatesRoundId += 1;
    const fetchId = lastFetchStartDatesRoundId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/transfer/startdates?channelId=${channel._id}&from=${encodeURIComponent(
        dateRoundNavigationRange.from
      )}&until=${encodeURIComponent(
        dateRoundNavigationRange.until
      )}&tickets=${encodeURIComponent(tickets)}${
        pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""
      }`
    )
      .then((response) => response.json())
      .then(({ startDates }) => {
        if (fetchId > lastFetchStartDatesRoundId) {
          // for fetch callback order
          return;
        }

        onLoading(false);
        setAllowedStartDatesRound(startDates);
      });
  };

  const fetchStartTimesRound = () => {
    lastFetchStartTimesRoundId += 1;
    const fetchId = lastFetchStartTimesRoundId;

    onLoading(true);

    fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/transfer/starttimes?channelId=${
        channel._id
      }&startDate=${encodeURIComponent(
        startDateRound
      )}&tickets=${encodeURIComponent(tickets)}${
        pricingOptionId ? `&pricingOptionId=${pricingOptionId}` : ""
      }`
    )
      .then((response) => response.json())
      .then(({ startTimes }) => {
        if (fetchId > lastFetchStartTimesRoundId) {
          // for fetch callback order
          return;
        }

        onLoading(false);
        setAllowedStartTimesRound(startTimes);
      });
  };

  const handleSubmit = () => {
    if (!pickupLocation) {
      toaster.warning(
        intl.formatMessage({
          id: "forms.transfer.pickupPoint.requiredMessage",
        }),
        { duration: 2 }
      );

      return;
    }

    if (!dropOffLocation) {
      toaster.warning(
        intl.formatMessage({
          id: "forms.transfer.dropOffPoint.requiredMessage",
        }),
        { duration: 2 }
      );

      return;
    }

    if (!startDate) {
      toaster.warning(
        intl.formatMessage({ id: "forms.transfer.date.requiredMessage" }),
        { duration: 2 }
      );

      return;
    }

    if (!startTime) {
      toaster.warning(
        intl.formatMessage({ id: "forms.transfer.time.requiredMessage" }),
        { duration: 2 }
      );

      return;
    }

    if (transferBookType === "roundtrip") {
      if (!startDateRound) {
        toaster.warning(
          intl.formatMessage({
            id: "forms.transfer.dateRound.requiredMessage",
          }),
          { duration: 2 }
        );

        return;
      }

      if (!startTimeRound) {
        toaster.warning(
          intl.formatMessage({
            id: "forms.transfer.timeRound.requiredMessage",
          }),
          { duration: 2 }
        );

        return;
      }

      if (
        moment(
          `${startDateRound} ${startTimeRound}`,
          "YYYY-MM-DD HH:mm"
        ).isSameOrBefore(
          moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm")
        )
      ) {
        toaster.warning(
          intl.formatMessage({
            id: "forms.transfer.dateTimeRound.validationMessage",
          }),
          { duration: 2 }
        );

        return;
      }
    }

    if (!transferService) {
      toaster.warning(
        intl.formatMessage({
          id: "forms.transfer.transferService.requiredMessage",
        }),
        { duration: 2 }
      );

      return;
    }

    const roundTripData =
      transferBookType === "roundtrip"
        ? { startDateRound, startTimeRound }
        : undefined;

    const pickupPointId = !!pickupLocation.provider
      ? "otherStart"
      : pickupLocation.id;

    const otherLocationStart =
      pickupPointId === "otherStart" ? pickupLocation : undefined;

    const dropOffPointId = !!dropOffLocation.provider
      ? "otherEnd"
      : dropOffLocation.id;

    const otherLocationEnd =
      dropOffPointId === "otherEnd" ? dropOffLocation : undefined;

    const dataToPost = {
      activityId: product._id,
      channelId: channel._id,
      pricingOptionId,
      dateToTravel: startDate,
      timeToTravel: startTime,
      tickets,
      luggagePerSize: { small: smallLuggageSize, large: largeLuggageSize },
      pickupPoints: [pickupPointId],
      dropOffPoints: [dropOffPointId],
      otherLocationStart,
      otherLocationEnd,
      transferService,
      transferBookType,
      ...(roundTripData || {}),
      originURL,
    };

    setLoadingSubmit(true);

    fetch(`${clientUrls.api}/public/tempsearch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(dataToPost),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setLoadingSubmit(false);

          toaster.warning(intl.formatMessage({ id: "common.genericError" }), {
            duration: 2,
          });

          return;
        }

        setLoadingSubmit(false);

        window.open(
          `https://${APPCONFIG.subdomain}/book/new/transfer/${data.resp._id}?lang=${intl.locale}`,
          "_top"
        );
      });
  };

  const handleChangeDateNavigation = ({ from, until }) => {
    setDateNavigationRange({ from, until });
  };

  const handleChangeDateRoundNavigation = ({ from, until }) => {
    setDateRoundNavigationRange({ from, until });
  };

  const handleChangePickupLocation = (value) => {
    setPickupLocation(value);
  };

  const handleChangeDropOffLocation = (value) => {
    setDropOffLocation(value);
  };

  const handleChangeTickets = (value) => {
    const isValidTickets = validateTickets({
      tickets,
      product,
    });

    if (!isValidTickets) {
      return;
    }

    setTickets(value);
  };

  const handleChangeTransferService = (value) => {
    const isValidTransferService = validateTransferService({
      transferService: value,
      smallLuggageSize,
      largeLuggageSize,
      tickets,
      product,
    });

    if (!isValidTransferService) {
      return;
    }

    setTransferService(value);
  };

  const handleChangeSmallLuggageSize = (value) => {
    setSmallLuggageSize(value);
  };

  const handleChangeLargeLuggageSize = (value) => {
    setLargeLuggageSize(value);
  };

  const handleChangeTransferBookType = (value) => {
    setTransferBookType(value);
  };

  // Whenever start date changes, reset start time and fetch new start times
  useEffect(() => {
    setStartTime(undefined);
    setAllowedStartTimes([]);

    if (!startDate) {
      return;
    }

    fetchStartTimes();
  }, [startDate]);

  // Round booking -> Whenever start date changes, reset start time and fetch new start times
  useEffect(() => {
    setStartTimeRound(undefined);
    setAllowedStartTimesRound([]);

    if (!startDateRound) {
      return;
    }

    fetchStartTimesRound();
  }, [startDateRound]);

  // If there is only one available start date, preselect it
  useEffect(() => {
    if (!fetchStartDatesKey || !!startDate) {
      return;
    }

    if (defaultStartDate) {
      const defaultStartDateFound = allowedStartDates.find(
        (startDate) => startDate === defaultStartDate
      );

      if (defaultStartDateFound) {
        setStartDate(defaultStartDateFound);
      }
    } else if (allowedStartDates.length === 1) {
      setStartDate(allowedStartDates[0]);
    }
  }, [fetchStartDatesKey]);

  // Check transfer service validity whenever tickets or luggage changes
  useEffect(() => {
    if (!transferService) {
      return;
    }

    const isValidTransferService = validateTransferService({
      transferService,
      smallLuggageSize,
      largeLuggageSize,
      tickets,
      product,
    });

    if (!isValidTransferService) {
      setTransferService(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets, smallLuggageSize, largeLuggageSize, transferService]);

  // Fetch start dates whenever date navigation changes
  useEffect(() => {
    setStartDate(undefined);
    setAllowedStartDates([]);

    fetchStartDates();
  }, [dateNavigationRange.from, dateNavigationRange.until]);

  // Fetch start dates whenever date navigation changes
  useEffect(() => {
    if (transferBookType !== "roundtrip") {
      return;
    }

    setStartDateRound(undefined);
    setAllowedStartDatesRound([]);

    fetchStartDatesRound();
  }, [
    transferBookType,
    dateRoundNavigationRange.from,
    dateRoundNavigationRange.until,
  ]);

  return (
    <div>
      <Row style={{ marginTop: 10 }}>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <RoutePicker
            product={product}
            channel={channel}
            defaultPickupPointId={defaultPickupPointId}
            defaultDropOffPointId={defaultDropOffPointId}
            pickupLocation={pickupLocation}
            dropOffLocation={dropOffLocation}
            onChangePickupPoint={handleChangePickupLocation}
            onChangeDropOffPoint={handleChangeDropOffLocation}
            brandColor={channel.normalColor}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 25 }}>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <DatePicker
            dateNavigationRange={dateNavigationRange}
            date={startDate}
            allowedDates={allowedStartDates}
            placeholder={intl.formatMessage({
              id: "forms.transfer.date.label",
            })}
            onChange={setStartDate}
            onChangeDateNavigation={handleChangeDateNavigation}
            brandColor={channel.normalColor}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <TimePicker
            time={startTime}
            allowedTimes={allowedStartTimes}
            placeholder={intl.formatMessage({
              id: "forms.transfer.time.label",
            })}
            disabled={!startDate}
            onChange={setStartTime}
            brandColor={channel.normalColor}
          />
        </Col>
      </Row>

      {!hideRoundTrip && (
        <div style={{ paddingLeft: 4, marginTop: 20 }}>
          <TransferBookRadio
            transferBookType={transferBookType}
            onChange={handleChangeTransferBookType}
            brandColor={channel.normalColor}
          />
        </div>
      )}

      {transferBookType === "roundtrip" && (
        <Row style={{ marginTop: 25 }}>
          <Col xl={6} lg={6} md={6} sm={6} xs={6}>
            <DatePicker
              dateNavigationRange={dateRoundNavigationRange}
              date={startDateRound}
              allowedDates={allowedStartDatesRound}
              placeholder={intl.formatMessage({
                id: "forms.transfer.dateRound.label",
              })}
              onChange={setStartDateRound}
              onChangeDateNavigation={handleChangeDateRoundNavigation}
              brandColor={channel.normalColor}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6}>
            <TimePicker
              time={startTimeRound}
              allowedTimes={allowedStartTimesRound}
              placeholder={intl.formatMessage({
                id: "forms.transfer.timeRound.label",
              })}
              disabled={!startDateRound}
              onChange={setStartTimeRound}
              brandColor={channel.normalColor}
            />
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: 25 }}>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <MinMaxPicker
            value={tickets}
            min={Number(product.minTickets)}
            max={Number(product.maxTickets)}
            onChange={handleChangeTickets}
            brandColor={channel.normalColor}
            icon="person"
          />
        </Col>

        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <TransferServicePicker
            transferService={transferService}
            transferServices={product.transferServices}
            placeholder={intl.formatMessage({
              id: "forms.transfer.transferService.label",
            })}
            minMaxTicketsPerTransferService={
              product.minMaxTicketsPerTransferService
            }
            smallLuggageSize={smallLuggageSize}
            largeLuggageSize={largeLuggageSize}
            tickets={tickets}
            brandColor={channel.normalColor}
            onChange={handleChangeTransferService}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 25 }}>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <MinMaxPicker
            min={0}
            value={smallLuggageSize}
            onChange={handleChangeSmallLuggageSize}
            brandColor={channel.normalColor}
            icon="smallLuggage"
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <MinMaxPicker
            min={0}
            value={largeLuggageSize}
            onChange={handleChangeLargeLuggageSize}
            brandColor={channel.normalColor}
            icon="maxLuggage"
          />
        </Col>
      </Row>

      <Footer
        viewMode={viewMode}
        onClick={handleSubmit}
        APPCONFIG={APPCONFIG}
        brandColor={channel.normalColor}
        loadingButton={loadingSubmit}
      />
    </div>
  );
};

export default TransferBookingParent;
