import React from "react";
import { Row, Col } from "react-grid-system";
import Skeleton from "react-loading-skeleton";

function LoadingSkeleton({ min, max, current, onChange, brandColor }) {
  return (
    <div>
      <Skeleton height={"20em"} style={{ margin: "0.5em auto 0.5em" }} />
      <Row style={{ margin: "2em auto" }}>
        <Col>
          <Row>
            {/* <Col></Col> */}
            <Col>
              <Skeleton
                circle={true}
                height={50}
                width={50}
                style={{ margin: "auto" }}
              />
            </Col>
            <Col></Col>
          </Row>
        </Col>
        <Col style={{ margin: "auto" }}>
          <Skeleton height={"2em"} />
        </Col>
        <Col>
          <Row>
            <Col></Col>
            <Col>
              <Skeleton
                circle={true}
                height={50}
                width={50}
                style={{ margin: "auto" }}
              />
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>
      <Skeleton height={"3em"} style={{ margin: "0.5em auto 0.5em" }} />
    </div>
  );
}

export default LoadingSkeleton;
