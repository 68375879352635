import React from "react";
import { useIntl } from "react-intl";
import { FaMapMarkerAlt, FaArrowDown, FaFlagCheckered } from "react-icons/fa";

import { Row, Col } from "react-grid-system";

import LocationPicker from "./LocationPicker";

import "./RoutePicker.scss";

const RoutePicker = ({
  product,
  channel,
  defaultPickupPointId,
  defaultDropOffPointId,
  pickupLocation,
  dropOffLocation,
  onChangePickupPoint,
  onChangeDropOffPoint,
  brandColor,
}) => {
  const intl = useIntl();

  return (
    <div className="route-picker">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 10,
          outline: "none",
        }}
      >
        <FaMapMarkerAlt size={"0.8rem"} color={brandColor} />

        <LocationPicker
          product={product}
          channel={channel}
          placeholder={intl.formatMessage({
            id: "forms.transfer.pickupPoint.placeholder",
          })}
          defaultLocationId={defaultPickupPointId}
          location={pickupLocation}
          onChange={onChangePickupPoint}
          brandColor={brandColor}
        />
      </div>

      <Row>
        <Col
          xl={5.5}
          lg={5.5}
          md={5.5}
          sm={5.5}
          xs={5.5}
          style={{ paddingRight: 0 }}
        >
          <hr
            style={{
              borderStyle: "dashed",
              borderColor: "#dbdfe3",
              margin: "10px 0px",
            }}
          />
        </Col>
        <Col
          xl={1}
          lg={1}
          md={1}
          sm={1}
          xs={1}
          style={{ textAlign: "center", padding: 0 }}
        >
          <FaArrowDown size={"0.8rem"} color={brandColor} />
        </Col>
        <Col
          xl={5.5}
          lg={5.5}
          md={5.5}
          sm={5.5}
          xs={5.5}
          style={{ paddingLeft: 0 }}
        >
          <hr
            style={{
              borderStyle: "dashed",
              borderColor: "#dbdfe3",
              margin: "10px 0px",
            }}
          />
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 10,
          outline: "none",
        }}
      >
        <FaFlagCheckered size={"0.8rem"} color={brandColor} />

        <LocationPicker
          product={product}
          channel={channel}
          placeholder={intl.formatMessage({
            id: "forms.transfer.dropOffPoint.placeholder",
          })}
          defaultLocationId={defaultDropOffPointId}
          location={dropOffLocation}
          onChange={onChangeDropOffPoint}
          brandColor={brandColor}
        />
      </div>
    </div>
  );
};

export default RoutePicker;
