import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  Dialog,
  Pane,
  Spinner,
  IconButton,
  ArrowLeftIcon,
  majorScale,
} from "evergreen-ui";

import moment from "moment";

import { v4 as uuidv4 } from "uuid";

import { withResizeDetector } from "react-resize-detector";

import ActivityBookingParent from "./activity/ActivityBookingParent";
import TransferBookingParent from "./transfer/TransferBookingParent";
import RentalBookingParent from "./rental/RentalBookingParent";

import ProductCard from "./ProductCard";

import "./BookingWidget.scss";
import clientUrls from "../urls";

import IntlMessage from "./shared/IntlMessage";
import LoadingSkeleton from "./shared/LoadingSkeleton";
import LoadingSpinner from "./shared/LoadingSpinner";

const VIEW_MODES = ["modal", "calendar"];

const BookingWidget = ({ width, height }) => {
  const intl = useIntl();

  const params = new URLSearchParams(window.location.search);

  const date = moment(params.get("date"), "YYYY-MM-DD").isValid()
    ? params.get("date")
    : undefined;

  const persons =
    Number.isInteger(Number(params.get("persons"))) &&
    Number(params.get("persons")) >= 1
      ? Number(params.get("persons"))
      : undefined;

  const pickupPointId = params.get("pickupPointId");
  const dropOffPointId = params.get("dropOffPointId");

  const transferService = params.get("transferService");

  const pricingOptionId = params.get("pricingOptionId")
    ? params.get("pricingOptionId")
    : undefined;

  const productId = params.get("productId");
  const channelId = params.get("channelId");

  const productsIds =
    typeof params.get("productsIds") === "string"
      ? params.get("productsIds").split(",")
      : [];

  const destinationId = params.get("destinationId");
  const categoryId = params.get("categoryId");

  const originURL =
    params.get("originUrl") !== null &&
    typeof params.get("originUrl") === "string" &&
    params.get("originUrl").length > 0
      ? params.get("originUrl")
      : window.location !== window.parent.location
      ? document.referrer
      : document.location.href;

  const viewMode = VIEW_MODES.includes(params.get("viewMode"))
    ? params.get("viewMode")
    : "modal";

  const hideCapacity = params.get("hideCapacity") === "1";

  const widgetKey = params.get("widgetKey") || uuidv4();

  const [channel, setChannel] = useState(undefined);
  const [loadingChannel, setLoadingChannel] = useState(true);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(undefined);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const [APPCONFIG, setAppConfig] = useState({});
  const [loadingAppConfig, setLoadingAppConfig] = useState(true);

  const [isLoadingContent, setIsLoading] = useState(false);

  useEffect(() => {
    if (!channelId) return;

    setLoadingChannel(true);
    setLoadingProducts(true);

    fetch(`${clientUrls.api}/public/channels/${channelId}`)
      .then((response) => response.json())
      .then(({ channel }) => {
        setChannel(channel);
        setLoadingChannel(false);
      });

    const page = 0;
    const size = 100;

    let queryParams = `page=${page}&size=${size}&channelId=${channelId}&embed[]=fromPrice`;

    if (destinationId) {
      queryParams += `&destinationId=${destinationId}`;
    }

    if (categoryId) {
      queryParams += `&categoryId=${categoryId}`;
    }

    if (productId) {
      queryParams += `&ids[]=${productId}`;
    } else if (productsIds.length > 0) {
      queryParams += `&${productsIds
        .map((productId) => `ids[]=${productId}`)
        .join("&")}`;
    }

    fetch(`${clientUrls.api}/public/products?${queryParams}`)
      .then((response) => response.json())
      .then(({ products: channelProducts }) => {
        setProducts(channelProducts);

        if (productId || channelProducts.length === 1) {
          const product = productId
            ? channelProducts.find((product) => product._id === productId)
            : channelProducts[0];

          setProduct(product);
        }

        setLoadingProducts(false);
      });
  }, [channelId, productId]);

  useEffect(() => {
    if (!channelId) return;

    setLoadingAppConfig(true);

    fetch(`${clientUrls.api}/public/widget/config?key=${channelId}`)
      .then((response) => response.json())
      .then(({ config }) => {
        setAppConfig(config);
        setLoadingAppConfig(false);
      });
  }, [channelId]);

  useEffect(() => {
    if (viewMode === "calendar") {
      const heightOffset = height + 20;

      window.parent.postMessage(
        { height: heightOffset, width, widgetKey },
        "*"
      );
    }
  }, [width, height, viewMode]);

  const renderProductBookingParent = () => {
    const { productType } = product;

    switch (productType) {
      case "transfer":
        return (
          <TransferBookingParent
            viewMode={viewMode}
            product={product}
            channel={channel}
            pricingOptionId={pricingOptionId}
            originURL={originURL}
            date={date}
            persons={persons}
            pickupPointId={pickupPointId}
            dropOffPointId={dropOffPointId}
            transferService={transferService}
            APPCONFIG={APPCONFIG}
            onLoading={setIsLoading}
          />
        );

      case "rental":
        return (
          <RentalBookingParent
            viewMode={viewMode}
            product={product}
            channel={channel}
            pricingOptionId={pricingOptionId}
            originURL={originURL}
            date={date}
            APPCONFIG={APPCONFIG}
            onLoading={setIsLoading}
          />
        );

      case "activity":
      case "tour":
      case "multiday":
        return (
          <ActivityBookingParent
            viewMode={viewMode}
            hideCapacity={product.private || hideCapacity}
            product={product}
            channel={channel}
            pricingOptionId={pricingOptionId}
            originURL={originURL}
            date={date}
            persons={persons}
            APPCONFIG={APPCONFIG}
            onLoading={setIsLoading}
          />
        );

      default:
        return <div />;
    }
  };

  const loadingBase = loadingChannel || loadingProducts || loadingAppConfig;
  const listingView = products.length > 1;

  if (viewMode === "modal") {
    return (
      <Dialog
        topOffset={"15vmin"}
        width={450}
        minHeightContent={"auto"}
        isShown
        title={
          <div>
            <LoadingSpinner
              loading={isLoadingContent || loadingBase}
              color={channel?.normalColor}
            />
            {listingView ? (
              product ? (
                <div>
                  <IconButton
                    style={{
                      display: "inline",
                      float: "left",
                      marginTop: -4,
                      borderRadius: "50%",
                      paddingTop: 3,
                      boxShadow:
                        "3px 3px 4px 0 rgba(0, 0, 0, 0.2), -2px -2px 3px 0 rgba(255, 255, 255, 0.2)",
                    }}
                    icon={ArrowLeftIcon}
                    marginRight={majorScale(2)}
                    onClick={() => setProduct(undefined)}
                  />
                  <span style={{ color: channel?.normalColor }}>
                    {product?.translations?.name?.[intl.locale] ||
                      product?.name?.EN}
                  </span>
                </div>
              ) : (
                <span style={{ color: channel?.normalColor }}>
                  <IntlMessage id="bookingwidget.selectService.label" />
                </span>
              )
            ) : (
              <div>
                <span style={{ color: channel?.normalColor }}>
                  {product?.translations?.name?.[intl.locale] ||
                    product?.name?.EN}
                </span>
              </div>
            )}
          </div>
        }
        hasFooter={false}
        hasClose={false}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        preventBodyScrolling
        containerProps={{ className: "dialog-content-modal" }}
        overlayProps={{ className: "dialog-overlay-modal" }}
        contentContainerProps={{ className: "dialog-content-container-modal" }}
      >
        {loadingBase ? (
          <LoadingSkeleton />
        ) : listingView ? (
          product ? (
            renderProductBookingParent()
          ) : (
            products.map((product) => (
              <div key={product._id} style={{ marginBottom: 10 }}>
                <ProductCard
                  channel={channel}
                  product={product}
                  viewMode={viewMode}
                  onClick={() => setProduct(product)}
                />
              </div>
            ))
          )
        ) : product ? (
          renderProductBookingParent()
        ) : null}
      </Dialog>
    );
  }

  if (viewMode === "calendar") {
    return (
      <div style={{ backgroundColor: "white", padding: 1 }}>
        {loadingBase ? (
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={400}
          >
            <Spinner />
          </Pane>
        ) : (
          <div>
            <LoadingSpinner
              loading={isLoadingContent || loadingBase}
              color={channel?.normalColor}
              noRadius
            />

            {listingView ? (
              product ? (
                <>
                  <div
                    style={{ marginTop: 20, marginBottom: 20, marginLeft: 5 }}
                  >
                    <IconButton
                      style={{
                        display: "inline",
                        float: "left",
                        marginTop: -4,
                        borderRadius: "50%",
                        paddingTop: 3,
                        boxShadow:
                          "3px 3px 4px 0 rgba(0, 0, 0, 0.2), -2px -2px 3px 0 rgba(255, 255, 255, 0.2)",
                      }}
                      icon={ArrowLeftIcon}
                      marginRight={majorScale(2)}
                      onClick={() => setProduct(undefined)}
                    />
                    <span
                      style={{
                        color: channel?.normalColor,
                        fontWeight: "bold",
                      }}
                    >
                      {product?.translations?.name?.[intl.locale] ||
                        product?.name?.EN}
                    </span>
                  </div>

                  {renderProductBookingParent()}
                </>
              ) : (
                products.map((product) => (
                  <div key={product._id} style={{ marginBottom: 10 }}>
                    <ProductCard
                      channel={channel}
                      product={product}
                      viewMode={viewMode}
                      onClick={() => setProduct(product)}
                    />
                  </div>
                ))
              )
            ) : product ? (
              <div>
                {listingView && (
                  <div
                    style={{ marginTop: 20, marginBottom: 20, marginLeft: 5 }}
                  >
                    <IconButton
                      style={{
                        display: "inline",
                        float: "left",
                        marginTop: -4,
                        borderRadius: "50%",
                        paddingTop: 3,
                        boxShadow:
                          "3px 3px 4px 0 rgba(0, 0, 0, 0.2), -2px -2px 3px 0 rgba(255, 255, 255, 0.2)",
                      }}
                      icon={ArrowLeftIcon}
                      marginRight={majorScale(2)}
                      onClick={() => setProduct(undefined)}
                    />
                    <span
                      style={{
                        color: channel?.normalColor,
                        fontWeight: "bold",
                      }}
                    >
                      {product?.translations?.name?.[intl.locale] ||
                        product?.name?.EN}
                    </span>
                  </div>
                )}

                {renderProductBookingParent()}
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
      </div>
    );
  }

  return <div />;
};

export default withResizeDetector(BookingWidget);
