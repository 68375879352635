const { REACT_APP_ENV } = process.env;

switch (REACT_APP_ENV) {
  case "production":
    module.exports = {
      api: "https://api.kleesto.com/api",
    };
    break;

  case "staging":
    module.exports = {
      api: "https://staging.api.kleesto.com/api",
    };
    break;

  default:
    module.exports = {
      api: "https://staging.api.kleesto.com/api",
    };
}
