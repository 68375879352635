export function getNumberWithCurrency(number, currency) {
  if (currency === "EUR") {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: currency,
    }).format(number);
  }

  if (currency === "CAD") {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: currency,
    }).format(number);
  }

  if (currency === "USD") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(number);
  }

  return number;
}
