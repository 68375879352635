import frMsg from "../locales/fr.json";

const FrLang = {
  locale: "fr",
  messages: {
    ...frMsg,
  },
};

export default FrLang;
