import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";

import { v4 as uuidv4 } from "uuid";

import clientUrls from "../../urls";

const mapLocationToOption = (location) => ({
  label: location.provider
    ? location.address
    : location.name !== location.address
    ? `${location.name} (${location.address})`
    : location.name,
  value: location.id || location.providerId,
  location,
});

const LocationPicker = ({
  product,
  channel,
  placeholder,
  defaultLocationId,
  location,
  onChange,
  brandColor,
}) => {
  const intl = useIntl();

  const [locations, setLocations] = useState([]);
  const [cacheLocationsKey, setCacheLocationsKey] = useState(undefined);

  const [initOptions, setInitOptions] = useState([]);
  const [cacheOptionsKey, setCacheOptionsKey] = useState(uuidv4());

  const [sessionId, setSessionId] = useState(uuidv4());

  const option = location && mapLocationToOption(location);

  const fetchLocations = (search = "") => {
    return fetch(
      `${clientUrls.api}/public/products/${
        product._id
      }/transfer/locations?channelId=${encodeURIComponent(
        channel._id
      )}&search=${search}&sessionId=${sessionId}`
    )
      .then((response) => response.json())
      .then(({ locations }) => {
        return locations;
      });
  };

  const handleChangeOption = (option) => {
    const { location } = option;
    const { provider, providerId } = location;

    if (!provider) {
      onChange(location);
    } else {
      fetch(
        `${clientUrls.api}/public/products/${
          product._id
        }/transfer/locations/${providerId}?channelId=${encodeURIComponent(
          channel._id
        )}&provider=${provider}&sessionId=${sessionId}`
      )
        .then((response) => response.json())
        .then(({ location }) => {
          onChange(location);

          // Refresh session id
          setSessionId(uuidv4());

          // Force re-render options to show latest selection as default option
          setCacheOptionsKey(uuidv4());
        });
    }
  };

  const handleLoadOptions = (inputValue) => {
    const initLoad = !inputValue;

    if (initLoad && location && location.provider) {
      const selectedOption = mapLocationToOption(location);

      return {
        options: [selectedOption, ...initOptions],
        hasMore: false,
      };
    }

    return fetchLocations(inputValue).then((locations) => {
      setLocations(locations);
      setCacheLocationsKey(uuidv4());

      const options = locations.map((location) =>
        mapLocationToOption(location)
      );

      if (initLoad) {
        setInitOptions(options);
      }

      return { options, hasMore: false };
    });
  };

  useEffect(() => {
    if (!defaultLocationId || !!location) {
      return;
    }

    const defaultLocation = locations.find(
      (location) => location.id === defaultLocationId
    );

    if (defaultLocation) {
      const locationToOption = mapLocationToOption(defaultLocation);
      handleChangeOption(locationToOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheLocationsKey, defaultLocationId]);

  const customStyles = {
    container: (base, state) => ({
      ...base,
      width: "100%",
      "&:focus-visible": {
        outline: "none",
      },
    }),

    indicatorSeparator: (base, state) => ({
      ...base,
      display: "none",
      "&:focus-visible": {
        outline: "none",
      },
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      "&:focus-visible": {
        outline: "none",
      },
    }),

    input: (base, state) => ({
      ...base,
      // ...state,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      cursor: "pointer",
      border: "none",
      "&:focus-visible": {
        outline: "none",
      },
    }),

    loadingMessage: (base, state) => ({
      ...base,
      fontSize: 13,
    }),

    noOptionsMessage: (base, state) => ({
      ...base,
      fontSize: 13,
    }),

    option: (base, state) => ({
      ...base,
      // ...state,
      paddingTop: 7,
      paddingBottom: 7,
      fontSize: 12,
      fontWeight: 500,
      // backgroundColor: "red",
      cursor: "pointer",
      backgroundColor: state.isSelected ? brandColor : "white",
      color: state.isSelected ? "white" : "gray",
      "&:focus-visible": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: brandColor,
        opacity: 0.7,
        color: "white",
      },
    }),
    control: (base, state) => ({
      ...base,
      minHeight: 32,
      maxHeight: 32,
      border: "none",
      boxShadow: "none",
      "&:focus-visible": {
        outline: "none",
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: 3,
      cursor: "pointer",

      "&:focus-visible": {
        outline: "none",
      },
    }),
    clearIndicator: (base, state) => ({
      ...base,
      padding: 3,
      "&:focus-visible": {
        outline: "none",
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: "0px 10px",
      fontSize: 13,
      fontWeight: 500,
      "&:focus-visible": {
        outline: "none",
      },
    }),
    placeholder: (base, state) => {
      return {
        ...base,
        fontSize: 13,
        color: "gray",
        fontWeight: 500,
        "&:focus-visible": {
          outline: "none",
        },
      };
    },
  };

  return (
    <AsyncPaginate
      styles={customStyles}
      debounceTimeout={800}
      value={option}
      placeholder={placeholder}
      loadOptions={handleLoadOptions}
      defaultOptions
      onChange={handleChangeOption}
      noOptionsMessage={() =>
        intl.formatMessage({
          id: "forms.transfer.noResults",
        })
      }
      loadingMessage={() =>
        intl.formatMessage({
          id: "forms.transfer.loadingLocations",
        })
      }
      cacheUniqs={[cacheOptionsKey]}
    />
  );
};

export default LocationPicker;
