import enLang from "./entries/en";
import elLang from "./entries/el";
import itLang from "./entries/it";
import deLang from "./entries/de";
import frLang from "./entries/fr";

const AppLocale = {
  en: enLang,
  el: elLang,
  it: itLang,
  de: deLang,
  fr: frLang,
};

export default AppLocale;
