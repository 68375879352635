import deMsg from "../locales/de.json";

const DeLang = {
  locale: "de",
  messages: {
    ...deMsg,
  },
};

export default DeLang;
