import React from "react";
import { Select } from "evergreen-ui";
import { FaEllipsisV } from "react-icons/fa";

import "./TransferServicePicker.scss";

const TransferServicePicker = ({
  transferService = "",
  transferServices,
  minMaxTicketsPerTransferService,
  smallLuggageSize,
  largeLuggageSize,
  tickets,
  onChange,
  placeholder,
  brandColor,
}) => {
  return (
    <div className="transfer-service-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 10,
          outline: "none",
        }}
      >
        <FaEllipsisV size={"0.8rem"} color={brandColor} />

        <Select
          required
          className="transfer-service-picker"
          width="100%"
          value={transferService}
          onChange={(e) => onChange(e.target.value)}
        >
          <option disabled selected value="">
            {placeholder}
          </option>

          {transferServices.map((transferService) => {
            let isValid = true;

            if (minMaxTicketsPerTransferService?.[transferService]) {
              const { minTickets, maxTickets, maxLuggage } =
                minMaxTicketsPerTransferService[transferService];

              if (minTickets) {
                isValid = isValid && Number(tickets) >= minTickets;
              }

              if (maxTickets) {
                isValid = isValid && Number(tickets) <= maxTickets;
              }

              if (maxLuggage) {
                const totalLuggage = smallLuggageSize + largeLuggageSize * 2;
                isValid = isValid && Number(totalLuggage) <= maxLuggage;
              }
            }

            return (
              <option
                key={transferService}
                value={transferService}
                disabled={!isValid}
              >
                {transferService}
              </option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default TransferServicePicker;
