import React from "react";
import { Grid, Row, Column } from "@input-output-hk/react-grid";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Button } from "evergreen-ui";

import "./TicketsPicker.scss";
import IntlMessage from "../shared/IntlMessage";

function TicketsPicker({ min, max, current, onChange, brandColor }) {
  return (
    <Grid className="tickets-picker">
      <Row className="tickets-picker-row">
        <Column size={1} className="tickets-picker-button-container">
          <Button
            disabled={current <= min}
            onClick={() => onChange(current - 1)}
          >
            <FaMinus
              size={35}
              color={current <= min ? "#b3b3b3" : brandColor}
            />
          </Button>
        </Column>
        <Column size={2} className="tickets-picker-number-container">
          <h4 className="tickets-picker-number">
            {current}{" "}
            <IntlMessage
              id={
                current === 1
                  ? "forms.availability.tickets.single.label"
                  : "forms.availability.tickets.plural.label"
              }
            />
          </h4>
        </Column>
        <Column size={1} className="tickets-picker-button-container">
          <Button
            disabled={current >= max}
            onClick={() => onChange(current + 1)}
          >
            <FaPlus size={35} color={current >= max ? "#b3b3b3" : brandColor} />
          </Button>
        </Column>
      </Row>
    </Grid>
  );
}

export default TicketsPicker;
