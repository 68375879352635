import React from "react";
import { Grid, Row, Column } from "@input-output-hk/react-grid";
import {
  FaMinus,
  FaPlus,
  FaUserAlt,
  FaSuitcaseRolling,
  FaSuitcase,
} from "react-icons/fa";
import { Button } from "evergreen-ui";
import Tooltip from "rc-tooltip";
import { FaRegQuestionCircle } from "react-icons/fa";

import "./MinxMaxPicker.scss";

import "rc-tooltip/assets/bootstrap_white.css";

function MinxMaxPicker({ min, max, value, onChange, brandColor, icon }) {
  return (
    <div className="minmax-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 10,
          outline: "none",
        }}
      >
        {icon === "person" ? (
          <FaUserAlt size={"0.8rem"} color={brandColor} />
        ) : icon === "smallLuggage" ? (
          <FaSuitcaseRolling size={"0.8rem"} color={brandColor} />
        ) : icon === "maxLuggage" ? (
          <FaSuitcase size={"0.8rem"} color={brandColor} />
        ) : null}

        {icon === "smallLuggage" && (
          <span style={{ marginLeft: 5 }}>
            <Tooltip
              placement="top"
              trigger={["hover", "click"]}
              overlay={<span>56cm x 35cm x 25cm</span>}
            >
              <FaRegQuestionCircle size={"0.8rem"} />
            </Tooltip>
          </span>
        )}

        <Grid className="minmax-picker">
          <Row className="minmax-picker-row">
            <Column size={1} className="minmax-picker-button-container">
              <Button
                disabled={value <= min}
                onClick={() => onChange(value - 1)}
              >
                <FaMinus
                  size={40}
                  color={value <= min ? "#b3b3b3" : brandColor}
                />
              </Button>
            </Column>
            <Column size={1} className="minmax-picker-number-container">
              <span className="minmax-picker-number">{value}</span>
            </Column>
            <Column size={1} className="minmax-picker-button-container">
              <Button
                disabled={value >= max}
                onClick={() => onChange(value + 1)}
              >
                <FaPlus
                  size={40}
                  color={value >= max ? "#b3b3b3" : brandColor}
                />
              </Button>
            </Column>
          </Row>
        </Grid>
      </div>
    </div>
  );
}

export default MinxMaxPicker;
