import React from "react";

import { Grid, Row, Column } from "@input-output-hk/react-grid";
import { FaChevronRight } from "react-icons/fa";
import { Pane, majorScale, Button, Text } from "evergreen-ui";
import styled from "styled-components";

import IntlMessage from "../IntlMessage";

import "./index.scss";

function Footer({ onClick, APPCONFIG, brandColor, loadingButton }) {
  return (
    <Grid className="footer">
      <Row
        className="footer-row"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Column xs={1} style={{ textAlign: "left", margin: "auto" }}>
          {!APPCONFIG.whiteLabelBranding && (
            <Pane style={{ marginLeft: 10 }}>
              <Text style={{ fontSize: "0.7rem", color: "#6e6e6e" }}>
                <IntlMessage id="common.poweredBy" />
                <Pane>
                  <img
                    src="/images/kleesto_logo_transparent.jpg"
                    alt="app-logo"
                    style={{ width: "5rem", marginLeft: 10 }}
                  />
                </Pane>
              </Text>
            </Pane>
          )}
        </Column>
        <Column xs={1} className="footer-button-container">
          <StyledButton
            height={majorScale(5)}
            onClick={onClick}
            brandColor={brandColor}
            isLoading={loadingButton}
          >
            <span>
              <IntlMessage id="common.continue" />{" "}
              <FaChevronRight style={{ fontSize: "0.5em", margin: "auto" }} />
            </span>
          </StyledButton>
        </Column>
      </Row>
    </Grid>
  );
}

const StyledButton = styled(Button)`
  background: ${(props) => props.brandColor} !important;

  span {
    color: white;
  }

  &:hover {
    background: ${(props) => props.brandColor} !important;

    span {
      color: white;
    }
  }
`;

export default Footer;
