import itMsg from "../locales/it.json";

const ItLang = {
  locale: "it",
  messages: {
    ...itMsg,
  },
};

export default ItLang;
