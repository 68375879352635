import React, { useEffect } from "react";
import { Select } from "evergreen-ui";
import { FaRegClock } from "react-icons/fa";

import moment from "moment";

import "./TimePicker.scss";

const timeFormat = "HH:mm";

const TimePicker = ({
  time = "",
  allowedTimes = [],
  disabled = false,
  onChange,
  brandColor,
  placeholder,
  addExtraMinute = false,
}) => {
  return (
    <div
      className="time-picker-container"
      style={{ background: disabled ? "#f6f7f8" : "white" }}
    >
      <div style={{ display: "flex", alignItems: "center", paddingLeft: 10 }}>
        <FaRegClock size={"0.8rem"} color={brandColor} />

        <Select
          disabled={disabled}
          required
          className="time-picker"
          width="100%"
          value={time}
          onChange={(e) => onChange(e.target.value)}
        >
          <option disabled selected value="">
            {placeholder}
          </option>

          {allowedTimes.map((time) => {
            const label =
              addExtraMinute && time !== "00:00"
                ? moment(time, timeFormat).add(1, "minutes").format(timeFormat)
                : time;

            return (
              <option key={time} value={time}>
                {label}
              </option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default TimePicker;
