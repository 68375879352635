import React from "react";
import { useIntl } from "react-intl";

import "./ProductCard.scss";

import { getNumberWithCurrency } from "../utils";
import IntlMessage from "./shared/IntlMessage";

const ProductCard = ({ product, channel, viewMode, onClick }) => {
  const intl = useIntl();

  const {
    fromPrice,
    minChargeAmount,
    private: isPrivate,
    currency,
    productType,
    productCategory,
  } = product;

  let priceToDisplay = fromPrice;

  if (typeof minChargeAmount !== "undefined" && minChargeAmount > fromPrice) {
    priceToDisplay = minChargeAmount;
  }

  return (
    <div
      className="product-card"
      style={{
        background: `linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.4)
          ), url("${product.profilePhoto[0].url}")`,

        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer",
      }}
      onClick={() => onClick(product._id)}
    >
      <header>
        <h2>{product.translations?.name?.[intl.locale] || product.name?.EN}</h2>
      </header>

      <footer style={{ backgroundColor: channel.normalColor }}>
        {productType === "transfer" ? (
          <h5 style={{ float: "left" }}>
            <IntlMessage
              id={`product.productType.productCategory.transfer.${productCategory}`}
            />
          </h5>
        ) : (
          typeof priceToDisplay !== "undefined" && (
            <h5 style={{ float: "left" }}>
              <IntlMessage id="product.fromPrice" />{" "}
              {getNumberWithCurrency(priceToDisplay, currency)}
            </h5>
          )
        )}

        <h5 style={{ float: "right" }}>
          <IntlMessage id={isPrivate ? "product.private" : "product.shared"} />
        </h5>
      </footer>
    </div>
  );
};

export default ProductCard;
